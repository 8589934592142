export const ourResourcesList = [
  {
    color: 'bg-blue-dark',
    text: 'main.resources.1',
  },
  // {
  //   color: 'bg-gray-4',
  //   text: 'main.resources.2',
  // },
  {
    color: 'bg-green-dark',
    text: 'main.resources.3',
  },
  {
    color: 'bg-blue',
    text: 'main.resources.4',
  },
  {
    color: 'bg-green',
    text: 'main.resources.5',
  },
  {
    color: 'bg-gray-3',
    text: 'main.resources.6',
  },
];
