import cn from 'classnames';
import { motion } from 'framer-motion';
import { Trans } from 'next-i18next';
import { Typography } from '../../shared/ui/typography';
import { useMain } from '@/store/api/main';

const achievementsList = [
  {
    title: '50+',
    subtitle: 'world_exp',
    className:
      'mb:max-tb:order-[1] dk:w-[234px] tb:w-[157px] dk:mr-[135px] tb:mr-[105px] mb:w-[190px] ',
  },
  {
    title: '15+',
    subtitle: 'russia_exp',
    className:
      'mb:max-tb:order-[0] dk:w-[125px] tb:w-[100px] dk:mr-[163px] tb:mr-[120px] mb:w-[81px] mb:max-tb:ml-[5px] mb:max-tb:mr-[64px]',
  },
  {
    title: '14',
    subtitle: 'cities',
    className:
      'mb:max-tb:order-[2] dk:w-[155px] tb:w-[140px] dk:mr-[125px] tb:mr-[58px] mb:w-[96px] mb:max-tb:ml-[5px] mb:max-tb:mr-[45px]',
  },
  {
    title: '30+',
    subtitle: 'brands',
    className:
      'mb:max-tb:order-[3] dk:w-[284px] tb:w-[199px] mb:w-[188px] mb:max-tb:ml-[5px]',
  },
];

export const Achievements = () => {
  const { key_numbers } = useMain();

  return (
    <ul className="mb:max-tb:flex-wrap mb:max-tb:gap-y-[24px] ml-[-5px] flex text-white">
      {achievementsList.map((item) => (
        <li
          key={item.subtitle}
          className={cn('relative z-30 flex flex-col', item.className)}
        >
          <Typography variant="h3">{item.title}</Typography>
          <Typography
            variant="textX"
            className="mb:max-dk:text-textS text-white"
          >
            <Trans components={{ br: <br className="mb:max-tb:hidden" /> }}>
              {key_numbers &&
                key_numbers[item.subtitle as keyof typeof key_numbers]}
            </Trans>
          </Typography>
          <motion.div
            className="absolute inset-0 top-[-10px] bg-black"
            style={{ clipPath: 'polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%)' }}
            initial={{ left: '-30%' }}
            whileInView={{ left: '100%' }}
            viewport={{ once: true }}
            transition={{ duration: 1, ease: 'easeInOut' }}
          />
        </li>
      ))}
    </ul>
  );
};
