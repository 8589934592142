import styles from './styles.module.scss';
import {
  defaultTextTransform,
  defaultTextTransition,
} from '@/shared/constants';
import { useBreakpoint } from '@/shared/hooks/useBreakpoints';
import { AnimatedTitle } from '@/shared/ui/animated';
import { ButtonText } from '@/shared/ui/button';
import { Typography, MTypography } from '@/shared/ui/typography';
import { mediaQueryKeys } from '@/shared/utils';
import { useMain, useMainQuery } from '@/store/api/main';
import cn from 'classnames';
import { motion, useInView } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useRef } from 'react';

export const MainTitleSection = () => {
  const { title_1, title_2, title_3, subtitle, title_4, title_5, services } =
    useMain();
  const { i18n } = useTranslation();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <section
      ref={ref}
      className="mb:pt-[90px] dk:pt-[100px] tb:pt-[80px] tb:pb-14 container relative z-10 text-white"
    >
      <Typography
        className={cn(
          'relative flex flex-col overflow-hidden uppercase',
          'dk:!text-[92px] dk:leading-[97px] tb:!text-[56px] tb:leading-[60px] mb:!text-[30px] mb:leading-[40px]',
          { hidden: i18n.language !== 'en' },
        )}
        variant="h1"
      >
        <AnimatedTitle
          isInView={isInView}
          delay={0.7}
          desktop={{ initialX: 100 }}
          tablet={{ initialX: 70 }}
          className="tb:w-fit"
        >
          {title_1} {title_2}
        </AnimatedTitle>
        <AnimatedTitle
          isInView={isInView}
          delay={0.7}
          desktop={{ initialX: -130 }}
          tablet={{ initialX: -70 }}
          className="dk:ml-[238px] tb:ml-[145px] w-fit"
        >
          {title_3}
        </AnimatedTitle>

        <AnimatedTitle
          isInView={isInView}
          delay={0.7}
          desktop={{ initialX: 100 }}
          tablet={{ initialX: 70 }}
          className="mb:max-tb:hidden"
        >
          {title_4?.replace('Y', '')}&nbsp;&nbsp;&nbsp; {title_5}
        </AnimatedTitle>
        <MTypography
          initial={{ opacity: 0 }}
          animate={{ opacity: isInView ? 1 : 0 }}
          transition={{ duration: 0.7, ease: 'easeInOut', delay: 1 }}
          className="tb:right-[55px] dk:right-[60px] mb:max-tb:hidden tb:max-dk:bottom-[72px] tb:max-dk:text-textS dk:bottom-[116px] tb:w-[400px] dk:w-[460px] absolute top-0 normal-case"
          variant="textM"
          as="span"
        >
          {subtitle}
        </MTypography>
        <AnimatedTitle
          isInView={isInView}
          className="tb:hidden"
          desktop={{ initialX: -248 }}
        >
          {title_4?.replace('Y', '')}&nbsp;&nbsp;&nbsp; {title_5}
        </AnimatedTitle>
        <motion.span
          className="absolute inset-0 bg-black"
          style={{ clipPath: 'polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%)' }}
          {...{ initial: { left: '-30%' }, animate: { left: '100%' } }}
          transition={{ duration: 1, ease: 'easeInOut' }}
        />
        <motion.span
          initial={{ clipPath: 'inset(0 100% 0 0)' }}
          animate={{
            clipPath: isInView ? 'inset(0 0 0 0)' : 'inset(0 100% 0 0)',
          }}
          transition={{ ease: 'easeInOut', duration: 0.4, delay: 1.4 }}
          className={cn(
            'absolute',
            'dk:h-[67px] dk:w-[56px] tb:h-[41px] tb:w-[35px] mb:h-[22px] mb:w-[19px]',
            'tb:left-[182px] tb:bottom-[15px] dk:left-[300px] dk:bottom-[25px] mb:left-[97px] mb:bottom-[12px]',
          )}
        >
          <Image alt="" src="/images/letters/letterU.svg" fill />
        </motion.span>
      </Typography>
      <Typography
        className={cn(
          'relative flex flex-col overflow-hidden uppercase',
          'dk:!text-[92px] dk:leading-[97px] tb:!text-[56px] tb:leading-[60px] mb:!text-[30px] mb:leading-[40px]',
          { hidden: i18n.language !== 'ru' },
        )}
        variant="h1"
      >
        <AnimatedTitle
          isInView={isInView}
          delay={0.7}
          desktop={{ initialX: 100 }}
          tablet={{ initialX: 70 }}
          className="mb:max-tb:hidden w-fit"
        >
          {title_1}
        </AnimatedTitle>
        <AnimatedTitle
          isInView={isInView}
          desktop={{ initialX: -360 }}
          className="tb:hidden w-fit"
        >
          {title_1}
        </AnimatedTitle>
        <AnimatedTitle
          isInView={isInView}
          delay={0.7}
          desktop={{ initialX: -75 }}
          tablet={{ initialX: -40 }}
          className="dk:mx-auto dk:pl-[175px] tb:pl-[108px] w-fit"
        >
          {title_2}
        </AnimatedTitle>

        <AnimatedTitle
          isInView={isInView}
          delay={0.7}
          desktop={{ initialX: 100 }}
          tablet={{ initialX: 70 }}
          className="mb:max-tb:hidden"
        >
          {title_3?.replace('у', '')}&nbsp;&nbsp;&nbsp; {title_4}
        </AnimatedTitle>
        <MTypography
          initial={{ opacity: 0 }}
          animate={{ opacity: isInView ? 1 : 0 }}
          transition={{ duration: 0.7, ease: 'easeInOut', delay: 1 }}
          className="tb:right-[55px] dk:right-[0px] mb:max-tb:hidden tb:max-dk:bottom-[72px] tb:max-dk:text-textS dk:bottom-[116px] tb:w-[440px] dk:w-[550px] absolute top-0 normal-case"
          variant="textM"
          as="span"
        >
          {subtitle}
        </MTypography>
        <AnimatedTitle
          isInView={isInView}
          className="tb:hidden"
          desktop={{ initialX: -248 }}
        >
          {title_3?.replace('у', '')}&nbsp;&nbsp;&nbsp; {title_4}
        </AnimatedTitle>
        <motion.span
          className="absolute inset-0 bg-black"
          style={{ clipPath: 'polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%)' }}
          {...{ initial: { left: '-30%' }, animate: { left: '100%' } }}
          transition={{ duration: 1, ease: 'easeInOut' }}
        />
        <motion.span
          initial={{ clipPath: 'inset(0 100% 0 0)' }}
          animate={{
            clipPath: isInView ? 'inset(0 0 0 0)' : 'inset(0 100% 0 0)',
          }}
          transition={{ ease: 'easeInOut', duration: 0.4, delay: 1.4 }}
          className={cn(
            'absolute',
            'dk:h-[67px] dk:w-[56px] tb:h-[41px] tb:w-[35px] mb:h-[22px] mb:w-[19px]',
            'tb:left-[235px] tb:bottom-[15px] dk:left-[387px] dk:bottom-[25px] mb:left-[126px] mb:bottom-[12px]',
          )}
        >
          <Image alt="" src="/images/letters/letterU.svg" fill />
        </motion.span>
      </Typography>
      <MTypography
        variant="textS"
        className="mb:max-tb:mt-4 mb:max-tb:block hidden"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ...defaultTextTransform, delay: 1.3 }}
      >
        {subtitle}
      </MTypography>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ...defaultTextTransform, delay: 1.3 }}
      >
        <ButtonText
          href="/services"
          isCapsVariant
          withArrow
          className="dk:right-[10px] mb:max-tb:static mb:max-tb:mt-[48px] tb:max-dk:right-[10px] tb:max-dk:bottom-[8px] dk:bottom-[16px] absolute uppercase text-white hover:text-white"
        >
          {services?.title}
        </ButtonText>
      </motion.div>
    </section>
  );
};
