import Seo from '@/seo/Seo';
import { defaultTextTransform } from '@/shared/constants';
import { useBreakpoint } from '@/shared/hooks/useBreakpoints';
import { useMain } from '@/store/api/main';
import { Achievements } from '@/widgets/achievements';
import cn from 'classnames';
import { motion, useInView } from 'framer-motion';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { ChooseUs } from './ui/ChooseUs';
import { MainTitleSection } from './ui/MainTitleSection';
import { OurMission } from './ui/OurMission';
import { OurResources } from './ui/OurResources';
import { OurServices } from './ui/OurServices';
import Script from 'next/script';
import { YandexMetrika } from '@/widgets/metrika';

// const Innovation = dynamic(() =>
//   import('./ui/Innovation').then((mod) => mod.Innovation),
// );
// const Clients = dynamic(() =>
//   import('./ui/Clients').then((mod) => mod.Clients),
// );
const ContactUs = dynamic(() =>
  import('./ui/ContactUs').then((mod) => mod.ContactUs),
);

export const HomePage = () => {
  const { locale } = useRouter();
  const { video, seo } = useMain();

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const isInViewVideo = useInView(videoRef, { amount: 1 });
  const { isMobile } = useBreakpoint();

  useEffect(() => {
    if (videoRef.current) {
      isInViewVideo && videoRef.current?.play();
      !isInViewVideo && videoRef.current?.pause();
    }
  }, [isInViewVideo]);

  return (
    <>
      <Seo seo={seo} />
      <YandexMetrika id={96166308} />
      <div className="relative">
        <MainTitleSection />
        <motion.div
          className="absolute inset-0 z-[5]"
          initial={{ backgroundColor: 'rgba(39,40,42,1)' }}
          animate={{ backgroundColor: 'rgba(39,40,42,0.6)' }}
          transition={{ ease: 'easeInOut', duration: 0.4, delay: 1.7 }}
        />
        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ ...defaultTextTransform, delay: 1 }}
          className={cn('absolute inset-0')}
        >
          <video
            ref={videoRef}
            src={`${process.env.NEXT_PUBLIC_API_URL}${video?.media?.url}`}
            controls={false}
            muted
            playsInline
            loop
            className="absolute h-[100%] w-[100%] object-cover"
          ></video>
        </motion.section>

        <section
          style={isMobile ? { margin: '0 auto' } : {}}
          className="mb:max-tb:ml-[11px] mb:max-tb:pt-[21px] tb:mt-[25px] dk:mt-10 container  pb-4"
        >
          <Achievements />
        </section>
      </div>
      <section
        className={cn(
          `mb:mt-[86px] mb:max-tb:flex-col mb:max-tb:gap-0 tb:mt-[108px] tb:gap-[40px] dk:mt-[156px] container flex`,
          {
            'dk:gap-[97px]': locale === 'ru',
            'dk:gap-[152px]': locale === 'en',
          },
        )}
      >
        <ChooseUs />
      </section>
      <OurServices />
      <OurResources />
      <OurMission />
      {/* <Innovation /> */}
      {/* ВРЕМЕННО */}
      {/* <Clients /> */}

      <ContactUs />
    </>
  );
};
