import { AnimatedTitle } from '@/shared/ui/animated';
import { Typography } from '@/shared/ui/typography';
import { useMain } from '@/store/api';
import cn from 'classnames';
import { motion, useInView } from 'framer-motion';
import { Trans, useTranslation } from 'next-i18next';
import { useRef } from 'react';
import { ourResourcesList } from '../constants';
import cls from './styles.module.scss';

export const OurResources = () => {
  const { resources } = useMain();

  const { i18n } = useTranslation();

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <section className=" bg-gray-5 dk:py-[121px] tb:max-dk:py-[84px] mb:max-tb:py-[64px]">
      <div className="mb:max-dk:flex-col tb:max-dk:gap-[40px] mb:max-tb:gap-[36px] container flex gap-[83px]">
        {i18n.language === 'en' ? <Typography
            variant="h2"
            className={cn(
                'flex w-full flex-col uppercase text-black',
                'dk:max-w-[36rem]',
            )}
        >
          <motion.span
              className="flex w-full"
              initial={{ clipPath: 'inset(0 100% 0 0)' }}
              animate={{
                clipPath: isInView ? 'inset(0 0 0 0)' : 'inset(0 100% 0 0)',
              }}
              transition={{ duration: 1 }}
          >
            <AnimatedTitle
                isInView={isInView}
                desktop={{ initialX: 40, animatedX: 0 }}
                tablet={{ initialX: 77, animatedX: 0 }}
                delay={1}
                className="dk:leading-[89px]"
            >
              {resources?.title_1}
            </AnimatedTitle>
          </motion.span>
          <motion.span
              className="flex"
              ref={ref}
              initial={{ clipPath: 'inset(0 100% 0 0)' }}
              animate={{
                clipPath: isInView ? 'inset(0 0 0 0)' : 'inset(0 100% 0 0)',
              }}
              transition={{ duration: 1 }}
          >
            <AnimatedTitle
                isInView={isInView}
                desktop={{ initialX: 40, animatedX: 35 }}
                tablet={{ initialX: 77, animatedX: 70 }}
                delay={1}
                className=""
            >
              {resources?.title_2}
            </AnimatedTitle>
          </motion.span>
        </Typography> : <Typography
            variant="h2"
            className={cn(
                'flex w-full flex-col uppercase text-black',
                'dk:max-w-[35rem]',
            )}
        >
          <motion.span
              className="flex w-full"
              initial={{ clipPath: 'inset(0 100% 0 0)' }}
              animate={{
                clipPath: isInView ? 'inset(0 0 0 0)' : 'inset(0 100% 0 0)',
              }}
              transition={{ duration: 1 }}
          >
            <AnimatedTitle
                isInView={isInView}
                desktop={{ initialX: 40, animatedX: 0 }}
                tablet={{ initialX: 77, animatedX: 0 }}
                delay={1}
                className="dk:leading-[89px]"
            >
              {resources?.title_1}
            </AnimatedTitle>
          </motion.span>
          <motion.span
              className="flex"
              ref={ref}
              initial={{ clipPath: 'inset(0 100% 0 0)' }}
              animate={{
                clipPath: isInView ? 'inset(0 0 0 0)' : 'inset(0 100% 0 0)',
              }}
              transition={{ duration: 1 }}
          >
            <AnimatedTitle
                isInView={isInView}
                desktop={{ initialX: 40, animatedX: 77 }}
                tablet={{ initialX: 77, animatedX: 96 }}
                delay={1}
                className=""
            >
              {resources?.title_2}
            </AnimatedTitle>
          </motion.span>
        </Typography>}
        <motion.ul
          initial={{ opacity: 0 }}
          animate={isInView && { opacity: 1 }}
          transition={{ duration: 0.7, delay: 1 }}
          viewport={{ once: true }}
          className="dk:gap-[52px] dk:mt-[14px] tb:max-dk:gap-[36px] mb:max-tb:gap-[30px] tb:max-dk:pl-[54px] flex flex-col"
        >
          {resources?.sections?.map(({ id, description }, i) => (
            <li
              key={id}
              className="dk:max-w-[483px] tb:max-w-[640px] mb:max-w-[345px] flex gap-4"
            >
              <span
                className={cn(
                  'flex-auto-0 block h-[18px] w-[18px]',
                  ourResourcesList[i].color,
                )}
              />
              <Typography variant="textM" className={cn("text-gray-1")}>
                {
                  // @ts-ignore
                  (<span dangerouslySetInnerHTML={{__html: description}}></span>)
                }
              </Typography>
            </li>
          ))}
        </motion.ul>
      </div>
    </section>
  );
};
