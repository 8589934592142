import { AnimatedTitle } from '@/shared/ui/animated';
import { Typography } from '@/shared/ui/typography';
import { useMain } from '@/store/api/main';
import cn from 'classnames';
import { motion, useInView } from 'framer-motion';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useRef } from 'react';
import { SharedTitleDesc } from '~shared';

const images = [
  'images/advantage_5.svg',
  'images/advantage_6.svg',
  'images/advantage_7.svg',
  'images/advantage_8.svg',
];

const ChooseOption = ({
  children,
  option,
  isFirst,
}: {
  children: React.ReactElement;
  option: SharedTitleDesc | undefined;
  isFirst?: boolean;
}) => {
  return (
    <motion.li
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.7, delay: isFirst ? 1 : 0 }}
    >
      <div className="mb:h-[101px] mb:w-[110px] tb:h-[114px] tb:w-[124px] dk:h-[160px] dk:w-[174px] relative">
        {children}
      </div>
      <Typography
        variant="titleL"
        className="mb:mb-2.5 mb:mt-6 mb:max-tb:text-titleM tb:mb-4 tb:mt-10 dk:max-w-[427px]"
      >
        {option?.title}
      </Typography>
      <Typography variant="textM" className="text-gray-1 mb:max-tb:text-textS">
        {option?.description}
      </Typography>
    </motion.li>
  );
};

const TextWrapper = ({
  children,
  isInView,
}: {
  children: React.ReactElement;
  isInView: boolean;
}) => {
  return (
    <motion.span
      className="flex w-full"
      initial={{ clipPath: 'inset(0 100% 0 0)' }}
      animate={{ clipPath: isInView ? 'inset(0 0 0 0)' : 'inset(0 100% 0 0)' }}
      transition={{ duration: 1, ease: 'easeInOut' }}
    >
      {children}
    </motion.span>
  );
};

export const ChooseUs = () => {
  const { locale } = useRouter();
  const { advantages } = useMain();

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const isRu = locale === 'ru';

  return (
    <>
      <div
        className={cn(
          'tb:w-full tb:max-dk:flex-auto-0 tb:max-w-[360px] dk:max-w-[490px]',
          { 'tb:max-w-[400px] dk:max-w-[546px]': isRu },
        )}
      >
        <Typography
          ref={ref}
          variant="h2"
          className="sticky top-20 flex flex-col uppercase"
        >
          <TextWrapper isInView={isInView}>
            <AnimatedTitle
              delay={1}
              isInView={isInView}
              desktop={{ initialX: 80, animatedX: isRu ? 62 : 0 }}
              tablet={{ initialX: 58, animatedX: isRu ? 45 : 0 }}
            >
              {advantages?.title_1}
            </AnimatedTitle>
          </TextWrapper>
          <span className="tb:hidden block">{advantages?.title_4}</span>
          <TextWrapper isInView={isInView}>
            <motion.span className="tb:block dk:pr-[44px] tb:max-dk:pr-[33px] dk:leading-[86px] hidden w-full text-end">
              {advantages?.title_2}
            </motion.span>
          </TextWrapper>
          <TextWrapper isInView={isInView}>
            <AnimatedTitle
              className="mb:max-tb:hidden dk:pl-9 tb:pl-[21px] mx-auto"
              delay={1}
              isInView={isInView}
              desktop={{ initialX: -125, animatedX: isRu ? 80 : 0 }}
              tablet={{ initialX: -88, animatedX: isRu ? 64 : 0 }}
            >
              {advantages?.title_3}
            </AnimatedTitle>
          </TextWrapper>
          <motion.span
            className="absolute inset-0 bg-white"
            style={{ clipPath: 'polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%)' }}
            initial={{ left: '-20%' }}
            animate={{ left: isInView ? '100%' : '-20%' }}
            transition={{ duration: 1, ease: 'easeInOut' }}
          />
        </Typography>
      </div>
      <ul className="dk:w-[489px] tb:w-[440px] mb:gap-[32px] mb:max-tb:mt-9 tb:gap-[40px] dk:gap-[48px] dk:mt-[3px] flex flex-col">
        {advantages?.sections?.map((advantage, i) => (
          <ChooseOption isFirst option={advantage} key={advantage.id}>
            <div className="mb:h-[101px] mb:w-[110px] tb:h-[114px] tb:w-[124px] dk:h-[160px] dk:w-[174px] relative">
              <Image alt="take-care" src={images[i]} fill />
            </div>
          </ChooseOption>
        ))}
      </ul>
    </>
  );
};
