import { defaultTextTransition } from '@/shared/constants';
import { AnimatedTitle } from '@/shared/ui/animated';
import { ButtonText } from '@/shared/ui/button';
import { Typography } from '@/shared/ui/typography';
import { useMain } from '@/store/api/main';
import cn from 'classnames';
import { motion, useInView } from 'framer-motion';
import { Trans } from 'next-i18next';
import Image from 'next/image';
import { useRef } from 'react';
import { Autoplay } from 'swiper';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';

export const OurServices = () => {
  const { services } = useMain();

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <section className="bg-blue-dark mb:max-tb:pb-[40px] mb:mt-[65px] mb:pt-16 tb:mt-[84px] tb:pt-[84px] tb:max-dk:pb-6 dk:mt-[120px] dk:pb-2 dk:pt-[120px] overflow-x-hidden">
      <div
        ref={ref}
        className="mb:mb-7 mb:max-tb:flex-col tb:mb-[64px] tb:gap-[74px] dk:mb-[116px] dk:gap-[143px] container relative flex"
      >
        <div
          className={cn(
            'dk:ml-[149px] dk:mt-[105px] tb:ml-[56px] tb:mt-[64px]',
          )}
        >
          <Typography
            variant="h2"
            className={cn(
              'dk:w-[530px] tb:w-[395px] flex w-full flex-col overflow-hidden uppercase text-white',
              'tb:absolute dk:right-[205px] dk:top-[2px] tb:top-[0px] tb:right-[165px]',
            )}
          >
            <motion.span
              className="flex w-full"
              initial={{ clipPath: 'inset(0 100% 0 0)' }}
              animate={{
                clipPath: isInView ? 'inset(0 0 0 0)' : 'inset(0 100% 0 0)',
              }}
              transition={{ duration: 1 }}
            >
              <AnimatedTitle
                className="dk:leading-[89px] self-end"
                isInView={isInView}
                desktop={{ initialX: 80 }}
                tablet={{ initialX: 77 }}
                delay={1}
              >
                {services?.title_1}
              </AnimatedTitle>
            </motion.span>
            <motion.span
              className="tb:justify-end flex w-full"
              initial={{ clipPath: 'inset(0 100% 0 0)' }}
              animate={{
                clipPath: isInView ? 'inset(0 0 0 0)' : 'inset(0 100% 0 0)',
              }}
              transition={{ duration: 1 }}
            >
              <AnimatedTitle
                className="self-end"
                isInView={isInView}
                desktop={{ initialX: -75 }}
                tablet={{ initialX: -40 }}
                delay={1}
              >
                {services?.title_2}
              </AnimatedTitle>
            </motion.span>
          </Typography>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: isInView ? 1 : 0 }}
            transition={{ ...defaultTextTransition, delay: 1.7 }}
            className="dk:w-[348px] tb:w-[311px] dk:mt-[24px] tb:mt-[12px] mb:mt-[18px] ml-auto"
          >
            <Swiper
              loop={true}
              centeredSlides={true}
              slidesPerView={1}
              spaceBetween={0}
              speed={700}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              navigation={true}
              modules={[Autoplay]}
            >
              {services?.slides?.map((item, idx) => (
                <SwiperSlide
                  className="tb:text-end dk:gap-[14px] mb:gap-[14px] tb:gap-[14px] dk:!h-[490px] tb:!h-[448px] mb:!h-[484px] !flex flex-col justify-end"
                  key={item.id}
                >
                  <Typography
                    variant="titleS"
                    className="tb:max-w-[265px] tb:self-end block !text-[16px] text-white"
                  >
                    {item.title}
                  </Typography>
                  <motion.div
                    className={cn(
                      'relative self-end',
                      'dk:w-[348px] dk:h-[436px] tb:w-[311px] tb:h-[390px] mb:w-[345px] mb:h-[430px]',
                    )}
                  >
                    <Image
                      className="h-full w-full object-cover"
                      alt="logo"
                      src={`${process.env.NEXT_PUBLIC_API_URL}${item.image?.url}`}
                      fill
                    />
                  </motion.div>
                </SwiperSlide>
              ))}
            </Swiper>
          </motion.div>
        </div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: isInView ? 1 : 0 }}
          transition={{ ...defaultTextTransition, delay: 1.7 }}
          className={cn(
            'dk:mt-[217px] mb:max-tb:mt-[38px] dk:max-w-[373px] tb:max-w-[415px]',
            'tb:mt-[168px]',
          )}
        >
          {/* <Typography
            variant="titleL"
            className="text-white mb:mb-6 mb:max-tb:text-titleM tb:mb-6"
          >
            {t('main.services.description')}
          </Typography> */}
          <Typography
            variant="textM"
            className="mb:mb-7 mb:max-tb:text-textS tb:mb-[50px] text-white/80"
          >
            <Trans components={{ br: <br /> }}>{services?.text}</Trans>
          </Typography>
          <ButtonText href="/services" isCapsVariant mode="light" withArrow>
            {services?.button}
          </ButtonText>
        </motion.div>
      </div>
      {/* <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: isInView ? 1 : 0 }}
        transition={{ ...defaultTextTransition, delay: 1.7 }}
      >
        <Accordion />
      </motion.div> */}
    </section>
  );
};
